import React, {useState, useEffect} from 'react';
import '../../css/conversation.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import ConvoBlock from './convoBlock';
import ActualConversation from './conversation';
import LeadData from './leadData';
import { useSelector, useDispatch } from 'react-redux';
import { setConversations } from '../../features/conversationSlice';
import {setOpenMessages} from '../../features/topBarSlice';
import {setConvoWindow} from '../../features/conversationSlice';
import { getConversations } from '../../utilities/appSetup';
import Icon from '../../assets/icons/icon';
import { setCall } from '../../features/callSlice';



function Conversations({ setLeadCard, setNewAppt, newAppt, setLoading, setCallerId, handleSoftPhone, unreadMessages, device, handleSetUnread, newMessages}) {
    //setCall
    const dispatch = useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const conversations=useSelector((state) => state.conversations.conversations);
    const currentConvoId=useSelector((state) => state.conversations.currentConvoId);
    const messagesState=useSelector((state) => state.topBar.messages);
    const [expanded, setExpanded] = useState(false);
    const [conversation, setConversation] = useState(false);
    const [lead, setLead] = useState(false);
    const [convoId, setConvoId] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [flowData, setFlowData]=useState(false);
    const handleInputChange = (content) => {
        setInputValue(content);
    };
    const handleUpdateConversation=(message)=>{
        let keyFound = false;

        const updatedState = conversations.map(obj => {
            if (obj.convo_id === message.convo_id) {
                keyFound = true;
                return message;
            }
            return obj;
        });
        if (!keyFound) {
            // Conversation not found, add the message to state
            updatedState.push(message);
        }
        const sortedConversations = [...updatedState].sort((a, b) => {
            return Number(b.time) - Number(a.time); // Descending order
        });
        //setMyConversations(sortedConversations);
        dispatch(setConversations(sortedConversations));
        //setConversations(sortedConversations); 
    }
    
    const clearRead= async (id)=>{

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    convo: id
                }) 
            };
          
            const response = await fetchWithDefaults('/conversation/clearUnreadMessage', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            handleSetUnread((unreadMessages - 1));
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    const timestamp = () => {
        const currentDate = new Date();
        const millisecondsSinceEpoch = currentDate.getTime();
        const unixTimestamp = Math.floor(millisecondsSinceEpoch / 1000);
        return unixTimestamp;
    };
    
    const handleClose=()=>{
        setExpanded(false)
        dispatch(setOpenMessages(false))
        dispatch(setConvoWindow(false));
    }
    const handleExpanded= async (convo)=>{
        if(expanded===false){
            setExpanded(true);
        }
        const response = await fetchWithDefaults(`/conversation/getConvo?convo=${convo}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData[0].lead){
            setLead(jsonData[0].lead)
        }
        //
        setConversation(jsonData[0].convo);
        setConvoId(convo)

        const updatedState = conversations.map(obj => {
            if (obj.convo_id === convo) {
                if (obj.message_read === 'no') {
                    clearRead(obj.convo_id);
                    // Create a new object with updated message_read property
                    return {
                        ...obj,
                        message_read: 'yes'
                    };
                }
            }
            // Return the original object if no update is necessary
            return obj;
        });
        dispatch(setConversations(updatedState));
    }
    const closeData = ()=>{
        setFlowData(false);
    }
    function UnixTimestampToDate(unixTimestamp) {
        // Create a new Date object from the Unix timestamp (in milliseconds)
        const date = new Date(unixTimestamp * 1000);
      
        // Get the month and day from the date object
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
      
        // Format the date as "MM/DD"
        const formattedDate = `${month}/${day}`;
      
        return formattedDate;
    }

    const travelPathway= async (lead,path)=>{
        try {
            const response = await fetchWithDefaults(`/travelPath?lead=${lead}&path=${path}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const stopFlow = async (id)=>{
        try {
            const response = await fetchWithDefaults(`/stopFlow?flow=${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(false);
            let index=0;
            setLead(prevData => {
                const newData = [...prevData];
                // Check if index is valid
                if (index >= 0 && index < newData.length) {
                // Update the field value for the object at the specified index
                newData[index] = {
                    ...newData[index],
                    ['wf_name']: null,
                    ['wf_id']: null
                };
                }
                return newData;
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleSendText= async (message,convoId,image)=>{
        const textToSend = message.replace(/<br>/g, '\n');
        const actualLead=lead[0].lead_id;
        const newMessage={
            lead_id: actualLead,
            message: textToSend,
            sent_from: "from",
            image:image,
            time: timestamp()
        }
        const pendingMessage={
            lead_id: actualLead,
            message: textToSend,
            pending:true,
            sent_from: "from",
            image:image,
            time: timestamp()
        }
        const toFrom=atob(convoId).split(':');
        const to=toFrom[1];
        const from=toFrom[0];
        setInputValue("");
        const updatedPending = [pendingMessage, ...conversation];
        const updatedConversation = [newMessage, ...conversation];
        setConversation(updatedPending);
       try {
            const textToSend = message.replace(/<br>/g, '\n');
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    to:to,
                    from:from,
                    body:textToSend,
                    team:agentInfo.teamId,
                    lead:lead[0].lead_id,
                    first:lead[0].borrower_first,
                    last:lead[0].borrower_last,
                    image:image,
                }) 
            };
            const response = await fetchWithDefaults('/sendText', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setConversation(updatedConversation);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect( () => {
        if(newMessages.length > 0){
            handleUpdateConversation(newMessages[0]);
        }
        if(convoId){
            if(newMessages[newMessages.length - 1].convo_id===convoId){
                handleExpanded(convoId);
            }
        }
    },[newMessages])

    useEffect( () => {
        if(currentConvoId){
            handleExpanded(currentConvoId)
        }
        return () => {
            setConversation(false)
        }
    },[currentConvoId])

    useEffect( () => {
        if(messagesState && conversations.length < 1){
            getConversations(handleSetUnread, agentInfo.agentId, dispatch);
        }
    },[messagesState])


    const makeDial= async (toNumber, callee, fromNum)=>{

        if(!fromNum){
            fromNum=lead[0].call_from;
        }
        const call= await device.connect({ params: { To: '+1'+toNumber , callerId: fromNum, user:agentInfo.agentId, tone: "default", leadId:lead[0].lead_id }});
        dispatch(setCall(call));
        handleSoftPhone(call.status());
        setCallerId({name:callee, lead_id:lead[0].lead_id});
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    team: agentInfo.teamId,
                    agentNumber:fromNum,
                    clientNumber:toNumber,
                    leadId:lead[0].lead_id
                }),
            };
            
            const response = await fetchWithDefaults('/queueDial', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleLeadData=(field, value)=>{
        let index=0;
        setLead(prevData => {
            const newData = [...prevData];
            // Check if index is valid
            if (index >= 0 && index < newData.length) {
              // Update the field value for the object at the specified index
              newData[index] = {
                ...newData[index],
                [field]: value
              };
            }
            return newData;
        });
    }
    /*const addTemplate = (template, images)=>{
        if(images){
            if(!images.includes('http')){
                const newImageLink='https://files.thehivelms.com/'+images;
                //setAttachedImage(newImageLink);
            }
            else{
                //setAttachedImage(images);
            }
        }
        
        let modifiedContent = Object.entries(lead[0]).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        modifiedContent = modifiedContent.replace(/\\n/g, '<br>');
        handleInputChange(inputValue+modifiedContent);
    }*/
    const openLead=(lead)=>{
        setLeadCard(lead);
    }
    return (
        <>
            
            
            <div className={`leaderBoardWrapper ${messagesState && expanded ? 'expanded': messagesState ? 'openedLeaderBoard' : ''}`}>
                <div className="quilityCloseWindow" onClick={()=>handleClose()} style={{zIndex: '2'}}><span className="fontello"><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div> 
               
                <div className="convoEntireWrapper">
                    <div className={`conversationLeft ${expanded ? 'convoAdjust' : ''}`}>
                        <div className="convoTitle">Recent Chats</div>
                        <div className="convoListInnerWrapper">
                            {conversations.status !== false && conversations.map((convo, index) => (
                                <ConvoBlock convo={convo} handleExpanded={handleExpanded} key={`conversation${index}`}/>
                            ))}
                        </div>
                    </div>
                    {expanded && (
                        <>
                            <div className="conversationCenter">
                                <div className="convoTitle" style={{margin: 'unset'}}>Active Conversation</div>
                                <ActualConversation setLoading={setLoading} travelPathway={travelPathway} lead={lead} handleInputChange={handleInputChange} inputValue={inputValue} convoId={convoId} handleSendText={handleSendText} conversation={conversation}/>
                            </div>
                            <div className="conversationRight rightAdjustment">
                                <div className="viewLeadButton solidQuilityActiveButton" onClick={()=>openLead(lead)}>Open Lead</div>
                                {lead && (
                                   lead.length > 0 && (
                                        <LeadData from="conversations" setNewAppt={setNewAppt} newAppt={newAppt} makeDial={makeDial} handleLeadData={handleLeadData} lead={lead}/>
                                    )
                                )}
                            </div>
                            {flowData && (
                                <div className="flowDataWrapper">
                                    <div className="flowDataContent">
                                        <div className="closeWindow" onClick={()=>closeData()}>X</div>
                                        {flowData.map((item, index) => (
                                            <span key={`flowData${index}`}>
                                                <div className="title">Workflow Info</div>
                                                <div className="flowDataInfoWrapper">
                                                    <div className="flowDataInfoTitle">
                                                        Workflow Name:
                                                    </div>
                                                    <div className="flowDataInfoInfo">
                                                        {item.name}
                                                    </div>
                                                </div>
                                                <div className="flowDataInfoWrapper">
                                                    <div className="flowDataInfoTitle">
                                                        Date Started:
                                                    </div>
                                                    <div className="flowDataInfoInfo">
                                                        {UnixTimestampToDate(item.start)}
                                                    </div>
                                                </div>
                                                <div className="flowDataInfoWrapper">
                                                    <div className="flowDataInfoTitle">
                                                        Workflow Length:
                                                    </div>
                                                    <div className="flowDataInfoInfo">
                                                        {item.totalResult} Actions
                                                    </div>
                                                </div>
                                                <div className="flowDataInfoWrapper">
                                                    <div className="flowDataInfoTitle">
                                                        Actions Remaining:
                                                    </div>
                                                    <div className="flowDataInfoInfo">
                                                        {item.activeCount}
                                                    </div>
                                                </div>
                                                <div className="buttonWrapper">
                                                    <div className="accentButton" style={{margin:'auto', marginTop:'25px'}} onClick={()=>stopFlow(item.theId)}>Stop Workflow</div>
                                                </div>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Conversations;
