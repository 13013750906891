import { fetchWithDefaults } from '../config/fetchDefault';
import { setLoadingGif } from '../features/loadingSlice';
const updateObject = (obj) => {
    if (obj.productOption === "6") {
      delete obj.term;
    }
    return obj;
  };

// Create an AbortController instance
let abortController = new AbortController();

const runVM = async (profile, setResults, leadId, dispatch) => {
    const updatedProfile = updateObject(profile);
    let term;
    if(profile.productOption==='6'){
        term="Whole_Life";
    }
    else{
        term=profile.term+'_Year_Term';
    }
    try {

        // cancel existing request first
        abortController.abort('[Funnel-runVM] Canceling stale request')

        // re-initialize abortContoller
        abortController = new AbortController()

       // setLoading(true);
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                leadId:leadId,
                profile:updatedProfile,
            }),
            signal: abortController.signal
        };
        dispatch(setLoadingGif(true));
        const response = await fetchWithDefaults('/virtualMentor/search', requestOptions);

        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        dispatch(setLoadingGif(false));
        const combineDataAndQuotes = (data, quotes) => {
            return data.map(product => {
              const matchingQuotes = quotes.filter(quote => quote.ProductUID === product.uid);
              return {
                ...product,
                quotes: matchingQuotes,
                tempId: product.uid+profile.faceAmount+'tl_'+term
              };
            });
        };

        if(jsonData){
            const results=combineDataAndQuotes(jsonData.data,jsonData.quotes)
            setResults(results);
        }
    }
    catch (error) {
       // dispatch(setLoadingGif(false));
        console.error("Error fetching data:", error);
        return false;
    }
};
const handleSelectedProducts=(faceAmount, product, setSelectedProducts)=>{
    setSelectedProducts((prevItems) => {
        const exists = prevItems.some(item => item.product.tempId === product.tempId);

        if (exists) {
          // Remove the item if it exists
          return prevItems.filter(item => item.product.tempId !== product.tempId);
        } else {
          // Add the item if it doesn't exist
          return [...prevItems, { face: faceAmount, product }];
        }
    });
}

const runVMMed = async (medication, setMedications) => {
    try {
        const response = await fetchWithDefaults(`/virtualMentor/medication/${medication}`);

        if (!response.ok) {
            return false;
        }

        const medications = await response.json()

        // return medications
        setMedications(medications)
    }
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}

const determineHealthRating = (tobaccoUse = 'no', rateClass = 'S') => {
    let prefix = rateClass
    let suffix = tobaccoUse === 'yes' ? 'T': 'NT'
    let rating = `${prefix}${suffix}`

    // PPT and SPT are not valid health ratings in Navigator
    if(rating === 'PPT'){
        rating = 'PT'
    }

    if(rating === 'SPT'){
        rating = 'ST'
    }

    return rating
}

const sendLeadToNavigator = async (agentCode, agentEmail, leadCode, age, sex, dispatch) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({agentCode,agentEmail,leadCode,age,sex})
    };
    try {
        dispatch(setLoadingGif(true));
        const response = await fetchWithDefaults('/virtualMentor/sendToNavigator', requestOptions);

        if (!response.ok) {
            return false;
        }

        const jsonData = await response.json();
        dispatch(setLoadingGif(false));

        return jsonData.startQuoteUrl ?? null
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
}

export {runVM, runVMMed, handleSelectedProducts, determineHealthRating, sendLeadToNavigator};