import React, { useState, useEffect } from 'react';
import '../../css/queue.css';
import LeadsQueue from '../leads/leadsQueue.js';
import areaCodes from '../areaCodes';
import DialModule from './dialModule.js';
import { useSelector, useDispatch } from 'react-redux';
import { setQueue, addCurrentLead } from '../../features/queueSlice';
import Icon from '../../assets/icons/icon.js';
import { setStartSession } from '../../features/callSlice.js';
import LeadCard from '../leads/leadCard.js';


function QueueModule({jumpIntoQueue,maxList, setCallerId, setLoading, newAppt, setNewAppt, setLeadCard, expandSession, textingNumbers, openQueue, holdingQueue, expanded, open, setMyQueue, device, formatDate}) {
    const dispatch = useDispatch();
    const [updateQueuePos, setUpdateQueuePos] = useState(false);
    const [permission, setPermission] = useState(false);
    const numbers=useSelector((state) => state.agent.phoneNumbers);
   /* const [term, setTerm] = useState('15');
    const [results, setResults] = useState(false);
    const [profile, setProfile] = useState(false);
    const [faceAmount, setFaceAmount] = useState('100000');
    const [selected, setSelected] = useState(1);
    const [smartQueue, setSmartQueue] = useState('smart');*/
    const queue=useSelector((state) => state.queue.queue);
    const resetQueuePositions = () => {
        const updatedData = queue.map((item, index) => {
            return {
                ...item,
                queuePos: index,
            };
        });
        setCurrentLead(updatedData[0]);
        setCurrentScript(updatedData[0].script);
        setCurrentLeadId(updatedData[0].lead_id);
        setUpdateQueuePos(true);
        dispatch(setQueue(updatedData));
    };
    const agentInfo=useSelector((state) => state.agent.userData);
    

    const [dialAmount, setDialAmount]=useState(false);
    const [currentScript, setCurrentScript]=useState(null);
    const [amountSelector, setAmountSelector]=useState(false);
    const [currentLead, setCurrentLead]=useState([]);
    const [currentLeadId, setCurrentLeadId]=useState(false);
    const [dialGroupDone, setDialGroupDone]=useState(false);

    const [callAccepted, setCallAccepted]=useState(false);
    const [newObjection, setNewObjection]=useState(false);
    
    const agentId = agentInfo.agentId;
    const teamId = agentInfo.teamId;
    const [call, setCall] = useState(null);
  
    const defaultNumber = numbers[0] ? numbers[0].number : null;
    const [selectedNumber, setSelectedNumber] = useState(defaultNumber);
    const [callState, setCallState] = useState('Disconnected');
    function searchAreaCode(areaCode) {
        const code = areaCode.toString().substring(0, 3);
        const foundState = areaCodes.find(state => {
        return Object.values(state).flat().includes(parseInt(code));
        });

        // If the area code is found, return the array of area codes
        // Otherwise, return an empty array
        return foundState ? Object.values(foundState)[0] : [];
    }
    function findMatchingNumber(array, areaCodes, areaCode) {
        // Step 1: Find match directly by area code
        const matchByAreaCode = array.find(item =>
          item.number.startsWith(`+1${areaCode}`)
        );
      
        if (matchByAreaCode) {
          return matchByAreaCode.number;
        }
      
        // Step 2: Find match in the areaCodes object
        const matchByAreaCodes = array.find(item => {
          const itemAreaCode = item.number.slice(2, 5); // Extract area code from '+1XXX'
          return areaCodes.includes(parseInt(itemAreaCode, 10));
        });
      
        if (matchByAreaCodes) {
          return matchByAreaCodes.number;
        }
      
        // Step 3: Default to the first number
        return array[0].number;
    }
    //const match=searchAreaCode('386');
    //const selected=findMatchingNumber(numbers,match,'305');

    const openDialAmount=()=>{
        setAmountSelector(!amountSelector);
    }
    
    const closeObjection=()=>{
        setNewObjection(false);
    }

    useEffect(() => {
        if(queue){
            dispatch(addCurrentLead(queue[0]));
            setCurrentLead(queue[0]);
            //setCurrentScript(queue[0].script);
            setCurrentLeadId(queue[0].lead_id);
        }
    },[queue])
    
    useEffect(() => {
        if(expanded){
            resetQueuePositions();
        }
    },[expanded])
    let outgoingCall='';
    const makeDial= async (toNumber, leadName, fromNum)=>{
        let dialFrom;
        if(fromNum){
            dialFrom=fromNum;
        }
        else{
            dialFrom=selectedNumber;
        }
        outgoingCall= await device.connect({ params: { To: '+1'+toNumber , callerId: dialFrom, user:agentId, tone: "default", leadId:currentLeadId }});
        setCall(outgoingCall);

    }
    const dialPackage=[{makeDial:makeDial,setDialGroupDone:setDialGroupDone, incrementQueue:'incrementQueue', myQueue: queue, currentLead:currentLead, dialAmount:dialAmount, device:device}];
    const handlCallAccepted=(type)=>{
        setCallAccepted(type);
    }

    const stopSession=()=>{
        openQueue();
        dispatch(setStartSession(false));
        jumpIntoQueue();
    }
    if(queue && updateQueuePos){
        return (
            <div className={` ${open ? 'queueModuleWrapper' : null}`}>         
                {open ? (
                    <div className={`queueWrapper ${expanded ? 'fullScreen' : ''}`}>
                        <div className="innerQueueWrapper">
                            <div className="actualQueue">
                                <div className="queueTabs">
                                    <div className={`queueTitle quilityActive`}>
                                        <div className="titleInner">My Queue</div>
                                    </div>
                                </div>
                                <DialModule callState={callState} setSelectedNumber={setSelectedNumber} expanded={expanded} dialAmount={dialAmount} setMyQueue={setMyQueue} amountSelector={amountSelector} device={device} selectedNumber={selectedNumber} call={call} currentLead={currentLead}  setDialAmount={setDialAmount} openDialAmount={openDialAmount} handlCallAccepted={handlCallAccepted} expandSession={expandSession} />

                                <LeadsQueue from={true} setLeadCard={setLeadCard} formatDate={formatDate} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>

                            </div>
                            {expanded ? (
                                <div className="centerQueue">
                                    <div className="quilityCloseWindow oppositeColor" onClick={()=>stopSession()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                                    <LeadCard theCall={call} setDialAmount={setDialAmount} jumpIntoQueue={jumpIntoQueue} dialPackage={dialPackage} from='session' permission={permission} setLoading={setLoading} textingNumbers={textingNumbers} newAppt={newAppt} setNewAppt={setNewAppt} data={[currentLead]} maxList={maxList} setCallerId={setCallerId} setCall={setCall} device={device} />
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                   
                    null
                )}
            
                {newObjection &&(
                    <div className="objectionViewWrapper">
                        <div className="objectionInnerWrapper">
                            <div className="objectionTitle">{newObjection.name}</div>
                            <div className="quilityCloseWindow" style={{top: '5px'}}onClick={()=>closeObjection()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                            <div className="objectionContent" dangerouslySetInnerHTML={{ __html: newObjection.content }}></div>
                        </div>
                    </div>
                )}
                
            </div>
        );
    }
}

export default QueueModule;
