import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import '../../../css/scripting.css';

//import { triggerCalendar } from '../../features/appointmentSlice';
import { useDispatch } from 'react-redux';


function ScriptBlock({title,content}) {
    const dispatch=useDispatch();
    const containerRef = useRef(null);


    
    return (
        <div className="scriptSectionBlock">
            <div className="scriptBlockTitle">
                {title}
            </div>
            <div className="ScriptBlockContent">{content}</div>
        </div>
    );
}





export default ScriptBlock;

