import React, {useState, useEffect, useRef} from 'react';
import '../../css/conversation.css';
import TextInputBar from '../callQueue/elements/textBar';
import { fetchPdf } from '../../utilities/messagingFunctions';
import { useSelector } from 'react-redux';
import Icon from '../../assets/icons/icon';

function SendBar({handleFileUpload, handleEmojiPicker, setAttachedImage, lead, sendBarRef, setLoading, convoId, handleSendText, inputValue, handleInputChange, handleChange, setTextBoxHeight, attachedImage}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const texts=useSelector((state) => state.texts.texts);
    const [filteredTexts, setFilteredTexts] = useState([]);
    const [textsTemplates, setTextsTemplates] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [variables, setVariables] = useState(false);
    const fileInputRef = useRef(null);
    const keysWithValue = [];

    const keysandvalues=(leadInfo)=>{
        for (const key in leadInfo) {
            if (leadInfo[key] !== null && leadInfo[key] !== undefined) {

                keysWithValue.push({key:key,value:leadInfo[key]});
            }
        }
    }
    keysandvalues(lead[0]);
    const openvars=()=>{
        setVariables(!variables);
    }
    const openTemplates=()=>{
        setTextsTemplates(!textsTemplates);
    }
    const addTemplate = (template, images)=>{

        if(images){
            if(!images.includes('http')){
                const newImageLink='https://files.thehivelms.com/'+images;
                setAttachedImage(newImageLink);
            }
            else{
                setAttachedImage(images);
            }
        }
        //this normalized from borrower ( our db) to lead. for instance DB is borrower_first, where variables and everything display lead_first. This is due to this originally being only for mortgage protection leads
        let modifiedContent = Object.entries(lead[0]).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        //if its an agent variable, replace the agent data
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        if(modifiedContent.includes('{lead_pdf}')){
            modifiedContent = modifiedContent.replace(/{lead_pdf}/g, '');
            fetchPdf(lead[0].pdf, setAttachedImage, setVariables, setLoading);
        }

        modifiedContent = modifiedContent.replace(/\\n/g, '<br>');
        handleInputChange(inputValue+modifiedContent);
        openTemplates();
        if(images){
            handleHeight(75);
        }
        else{
            handleHeight(25);
        }
    }
    const handleHeight = (height) => {
        const newheight=sendBarRef.current.clientHeight + 50;
        console.log(newheight)
      //  setTextBoxHeight(newheight);       
    };
    const filterTexts = (searchTerm) => {
        const filtered = texts.filter(text => text.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredTexts(filtered);
    };
    const handleCursorPosition=(pos)=>{
        setCursorPosition(pos);
    }
    const handleClick = (value,key) => {
        if(key==='pdf'){
            fetchPdf(value, setAttachedImage, setVariables, setLoading);
        }
        else{
            const updatedValue = inputValue.slice(0, cursorPosition) + value + inputValue.slice(cursorPosition);
            handleInputChange(updatedValue);
            setCursorPosition(cursorPosition + value.length);
            setVariables(!variables);
        }
    };
    const resetSize=()=>{
       // setTextBoxHeight(150)
    }
    const handleImageClick = () => {
        fileInputRef.current.click(); 
    };
    useEffect(() => {
        setFilteredTexts(texts);
    }, []);
    return (
        <div className="completeSendBar">
            <div className="sendBar">
                <div className="inputBarWrapper">
                    <TextInputBar from='bigConvo' handleCursorPosition={handleCursorPosition} handleHeight={handleHeight} inputValue={inputValue} handleChange={handleChange} />
                </div>
                <div className="sendButton" style={{marginLeft: '0px'}}>
                    <span className="fontello" style={{marginTop: '-3px'}} onClick={() => { handleSendText(inputValue, convoId, attachedImage); resetSize(); setAttachedImage(false);}}><Icon name={'send-q'} height='40' width='30' className="" adjust="fontello" /></span>
                    </div>
            </div>

            <div className="sendBarOptions" style={{paddingBottom: '6px'}}>
                <div className="optionButton" onClick={() => handleEmojiPicker()} ><span>&#9786;</span></div>
                <div className="optionButton " onClick={handleImageClick}>
                <span className="fontello" style={{marginTop: '-7px'}}><Icon name={'image-q'} height='38' width='30'  className="" adjust="fontello" /></span>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                </div>
                <div className="optionButton"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'clock-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                <div className="optionButton" onClick={()=>openvars()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-brackets-plus'} height='38' width='30'  className="" adjust="fontello" /></span></div>
                {variables && (
                    <div className="variableWindow">
                        <div className="innerWrapper">
                            {keysWithValue.map((variable, index) => (
                                <div class="leadInfoLine" key={`variable${index}`}><span class="dataFieldTitle">{variable.key.replace('_',' ')}</span><span class="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{cursor:'pointer'}}>Click To Use</span></div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="optionButton" onClick={()=>openTemplates()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'file-text-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                {textsTemplates && (
                    <div className="variableWindow">
                        <input
                            type="text"
                            placeholder="Search templates..."
                            className="templateSearch"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                filterTexts(e.target.value);
                            }}
                        />
                        <div className="innerWrapper">
                            {filteredTexts.map((text, index) => (
                                <div className="leadInfoLine" key={`texttemplate${index}`}>
                                    <span className="dataFieldTitle">{text.name}</span>
                                    <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SendBar;


