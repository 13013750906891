import React, { useState, useEffect, useRef } from 'react';
import '../../../../css/qualifier.css';



import ReccomendationBlock from '../../../reccomendation/reccomendationBlock';
import ExtraInfo from '../../../reccomendation/extraInfo';

function Recommendation({ selectedTab, handleSelectedTab, selectedProducts, setSelectedProducts, handleProfileUpdate,setFaceAmount,faceAmount,term,setTerm,results,lead, profile, possibleConditions, healthRating, setHealthRating}) {
    const [extraInfo, setExtraInfo] = useState(false);
    //const [selectedTab, setSelectedTab] = useState('term');
    //const [recWithQuote, setRecWithQuote] = useState(false);
    const [recHealthRating, setRecHealthRating] = useState('S')
    const [recPlanType, setRecPlanType] = useState('');

    const closePopup=()=>{
        setExtraInfo(false);
    }
    const handleTermChange=(event)=>{
        setTerm(event.target.value)
        handleProfileUpdate('term',event.target.value);
    }
    const handleFaceChange=(value)=>{
        setFaceAmount(value)
        //handleProfileUpdate('faceAmount',value);
    }
    const handleSetFaceAmount=(value)=>{
        handleProfileUpdate('faceAmount',value)
    }

    const handleHealthRating=(event)=>{
        let selectedRating = event.target.value

        updateHealthRating(selectedRating)
    }

    const updateHealthRating = (selectedRating) => {
        let suffix = 'NT'
        setRecHealthRating(selectedRating)

        if(profile.tobacco !== 'no'){
            suffix = 'T'
        }

        let calculatedHealthRating = `${selectedRating}${suffix}`

        if(calculatedHealthRating === 'PPT'){
            calculatedHealthRating = 'PT'
        }

        if(calculatedHealthRating === 'SPT'){
            calculatedHealthRating = 'ST'
        }

        setHealthRating(calculatedHealthRating)
        handleProfileUpdate('healthRating', calculatedHealthRating)
    }
    const handlePlanType=(event)=>{
        setRecPlanType(event.target.value)
        //handleProfileUpdate('healthRating', calculatedHealthRating)
    }

    const healthRef = useRef()

    const tobaccoPlanTypes = [
        {
            Label: 'Choose Type',
            Vaule: '',
        },
        {
            Label: 'Level',
            Vaule: 'level',
        },
        {
            Label: 'Graded',
            Vaule: 'graded',
        },
    ]

    const nonTobaccoPlanTypes = [
        {
            Label: 'Choose Type',
            Vaule: '',
        },
        {
            Label: 'Level',
            Vaule: 'level',
        },
        {
            Label: 'Level Preferred',
            Vaule: 'level pre',
        },
        {
            Label: 'Graded',
            Vaule: 'graded',
        },
    ]

    const [planTypeOptions, setPlanTypeOptions] = useState(profile.tobacco === 'no' ? nonTobaccoPlanTypes : tobaccoPlanTypes);
    useEffect(() => {
        if(selectedTab==='term'){
            if(faceAmount !=='100000'){
                handleFaceChange('100000')
                handleSetFaceAmount('100000')
            }
        }
        else if(selectedTab==='whole'){
            if(faceAmount !=='25000' && faceAmount >= '100000'){
                handleFaceChange('25000')
                handleSetFaceAmount('25000')
            }
        }
    }, [selectedTab]);

    useEffect(() => {
        // check and see if the profile health rating needs to be updated
        // the tobacco question isn't triggering the update at the moment
        const tobaccoRatings = ['ST','PT']
        const nonTobaccoRatings = ['PPNT','PNT','SPNT','SNT']
        // console.info('healthRef',healthRef)
        if(selectedTab==='term' && healthRef.current !== null && ((profile.tobacco === 'yes' && !tobaccoRatings.includes(healthRating)) || (profile.tobacco !== 'yes' && !nonTobaccoRatings.includes(healthRating)))){
            updateHealthRating(healthRef.current.value)
        } else if(selectedTab==='whole'){
            if(lead[0].tobacco === 'no'){
                setPlanTypeOptions(nonTobaccoPlanTypes)

                if(recPlanType == 'level pre'){
                    updateHealthRating('PP')
                } else {
                    updateHealthRating('S')
                }
            } else {
                setPlanTypeOptions(tobaccoPlanTypes)

                if(recPlanType === 'level pre'){
                    setRecPlanType('')
                }

                updateHealthRating('S')
            }
        }
     }, [lead[0].lead_id,lead[0].tobacco])

    useEffect(() => {
        if(selectedTab==='whole'){
            if(recPlanType == 'level pre' && lead[0].tobacco === 'no'){
                updateHealthRating('PP')
            } else {
                updateHealthRating('S')
            }
        }
    }, [recPlanType])

    return (
        <div className='recWrapper'>
            <div className="productTabs">
                <div className={`productTab ${selectedTab==='term' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('term')}>Term Products</div>
                <div className={`productTab ${selectedTab==='whole' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('whole')}>Whole Life</div>
            </div>
            <div className="recOptions">

                <div className="recInputHolder">
                    <div className="recInputTitle">
                        Face Amount
                    </div>
                    <input type="text" className="recInput" onChange={(e)=>handleFaceChange(e.target.value)} value={faceAmount} onBlur={(e)=>handleSetFaceAmount(e.target.value)}/>
                </div>
                {selectedTab==='term' && (
                    <div className="recInputHolder">
                        <div className="recInputTitle">
                            Term Length
                        </div>
                        <select className="recInputSelect" onChange={handleTermChange} value={term}>
                            <option value="10">10 Year</option>
                            <option value="15">15 Year</option>
                            <option value="20">20 Year</option>
                            <option value="25">25 Year</option>
                            <option value="30">30 Year</option>
                            <option value="35">35 Year</option>
                            <option value="40">40 Year</option>
                        </select>
                        {/*<input type="text" className="recInput"/>*/}
                    </div>
                )}
                {selectedTab==='term' ? (
                    <div className="recInputHolder">
                        <div className="recInputTitle">
                            Rate Class
                        </div>
                        <select className="recInputSelect" onChange={handleHealthRating} value={recHealthRating} ref={healthRef}>
                            <option value="PP">Preferred Plus</option>
                            <option value="P">Preferred</option>
                            <option value="SP">Standard Plus</option>
                            <option value="S">Standard</option>
                        </select>
                    </div>
                ) : (
                    <div className="recInputHolder">
                        <div className="recInputTitle">
                            Plan Type
                        </div>
                        <select className="recInputSelect" onChange={handlePlanType} value={recPlanType}>
                            {/* <option value="">Choose Type</option>
                            <option value="level">Level</option>
                            <option value="level pre">Level Preferred</option>
                            <option value="graded">Graded</option> */}
                            {planTypeOptions.map(({Label, Vaule}, index)=> (
                                <option value={Vaule} key={`planTypeOption${index}`}>{Label}</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div className="recResults">
                {results && results.map((result, index) => (
                    result.quotes.length > 0 && (
                        selectedTab==='whole' ? (
                            recPlanType==='' ? (
                                <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} setSelectedProducts={setSelectedProducts} setExtraInfo={setExtraInfo}/>
                            ) : recPlanType==='graded' && result.plan_type==='graded' ? (
                                <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} setSelectedProducts={setSelectedProducts} setExtraInfo={setExtraInfo}/>
                            ) : recPlanType==='level' && result.plan_type==='level' ? (
                                <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} setSelectedProducts={setSelectedProducts} setExtraInfo={setExtraInfo}/>
                            ) : recPlanType==='level pre' && result.plan_type==='level pre' && (
                                <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} setSelectedProducts={setSelectedProducts} setExtraInfo={setExtraInfo}/>
                            )

                        ) : (
                            (!result.plan_type || parseInt(result.plan_type) === parseInt(term)) && (
                                <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} setSelectedProducts={setSelectedProducts} setExtraInfo={setExtraInfo}/>
                            )
                        )
                    )
                ))}
            </div>
            {extraInfo && (
                <ExtraInfo extraInfo={extraInfo} closePopup={closePopup} />
            )}
        </div>
    );

}

export default Recommendation;

