import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import '../../css/scripting.css';
//import { triggerCalendar } from '../../features/appointmentSlice';
import { useDispatch } from 'react-redux';
import ScriptBlock from './elements/scriptBlock';
import { updateLeadData } from '../../utilities/leadFunctions';
import FdaBlock from '../qualifier/sections/fdaBlock';


function Script({lead,setProfile,handleLeadData,possibleConditions, setPossibleConditions,setLead,profile}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [selectedScript, setSelectedScript]= useState(0);
    const [addedSpouse, setAddedSpouse] = useState(false);
    const [dayTime1, setDayTime1] = useState('');
    const [dayTime2, setDayTime2] = useState('');
    const profileAttr=['borrower_age','feet','inches','weight','borrower_sex','tobacco']
    const types=
        {'mortgage protection':'Mortgage Protection',
        'b1':'Mortgage Protection',
        'b2':'Mortgage Protection',
        'b3':'Mortgage Protection',
        'b4':'Mortgage Protection',
        'b5':'Mortgage Protection',
        'b6':'Mortgage Protection',
        'mia':'Mortgage Protection',
        'mib':'Mortgage Protection',
        'mic':'Mortgage Protection'}
    
    const coLeadInput=['co_borrower_first','co_borrower_last','co_borrower_age','co_borrower_dob','co_borrower_weight','co_borrower_height','co_borrower_cell','co_borrower_email','co_borrower_income','co_borrower_occupation'];
    const coLeadDrop=['co_borrower_tobacco','co_borrower_sex']
    const handleScriptInput=(field,value)=>{
        handleLeadData(field,value);
    }
    const handleSetProfile= (column, value)=>{
        setProfile(prevProfile => ({...prevProfile,[column]:value}));
    }
    const handleDropDown = (field, value) => {
        if(field==='ben_type' && value==='spouse'){
            handleUpdateLead('marital_status', 'Married')
        }
        if(profileAttr.includes(field)){
            handleSetProfile(field,value)
        }
        updateLeadData(field, value, lead[0].lead_id, agentInfo.agentId);
        handleLeadData(field,value);
    }
    const handleUpdateLead=(field,value)=>{
        if(profileAttr.includes(field)){
            handleSetProfile(field,value)
        }
        updateLeadData(field, value, lead[0].lead_id, agentInfo.agentId);
    }

    const cases=[
        {"Mortgage Protection":"pay your mortgage in the event of a death or disability"}
    ]
    const finalType=types[lead[0].lead_type.toLowerCase()] ? types[lead[0].lead_type.toLowerCase()] : lead[0].lead_type;
    const elements=[
    [
        {
            title:"Establish the Reason You're Calling",
            content:(
                <>
                    <p>
                        Hey, <b>{lead[0].borrower_first}</b>
                        <br />
                        <br />
                        (Wait for response)
                        <br />
                        <br />
                        Hey, this is {agentInfo.firstName} {agentInfo.lastName}. I'm calling about your
                            {finalType==='Mortgage Protection' ? (
                                <> mortgage in the amount of $ <input className="qualifierFieldInline" style={{width:'60px'}} type="text" onBlur={(e)=>handleUpdateLead('mortgage', e.target.value)} onChange={(e)=>handleScriptInput('mortgage', e.target.value)} value={lead[0].mortgage ? lead[0].mortgage : ''} placeholder='mortgage'/> over <b>{lead[0].address ? 'at '+lead[0].address : lead[0].city ? 'in '+lead[0].city : 'in '+lead[0].state}</b>.</>
                            ) : (
                                <> request for quotes.</>
                            )}
                        <br />
                        <br />
                        <b>[Pause and wait for response.]</b>
                        <br />
                        <br />
                        I was getting back to you about the request for <b>{finalType}</b> coverage that is designed to {cases[0][lead[0].lead_type]}. 
                        I just need to quickly verify some of the information you provided so we can get those options out to you.
                        <br />
                        <br />
                        <b>[Start filling out a client qualification form.]</b>
                        <br />
                        <br />
                        I have here your age as <input className="qualifierFieldInline" style={{width:'25px'}} type="text" onBlur={(e)=>handleUpdateLead('borrower_age', e.target.value)} onChange={(e)=>handleScriptInput('borrower_age', e.target.value)} value={lead[0].borrower_age ? lead[0].borrower_age : ''} placeholder='Age'/>. Is that correct?
                        <br />
                        <br />
                        
                    </p>
                    <span className="numberList"><span className="number">1.</span> Have you used any nicotine or tobacco in the last 12 months?
                        <div className="leadData" style={{ width: '50%', margin: '10px 0', display: 'block' }}>
                            <select
                                className="outlineButton filterButton"
                                onChange={(e) => handleDropDown('tobacco', e.target.value)}
                                value={lead[0].tobacco || ''}
                            >
                                <option value="no">Non-Tobacco</option>
                                <option value="yes">Tobacco</option>
                            </select>
                        </div>
                        {lead[0].tobacco==='no' && (
                            <p>
                                You don't plan on starting this weekend, do you?<br/><b>[Get a laugh and put them at ease.]</b>
                            </p>
                        )}
                    </span>
                    <span className="numberList">
                        <span className="number">2.</span> 
                        How's your health, are you in pretty good health? What medications does your doctor have you on? How about hospital stays, ambulance rides or major surgeries for you in the past 10 years or so? And how about <b>{lead[0].co_borrower_first ? lead[0].co_borrower_first : 'your spouse' }</b>, anything for them? Is there any history of things like High Blood Pressure, Diabetes, Cancer or heart attack for either of you? <span className="addSpouseButton solidQuilityActiveButton" onClick={()=>{setAddedSpouse(true)}}>+ Add Spouse</span>
                        <div className="scriptLineBreak">
                            <FdaBlock setPossibleConditions={setPossibleConditions} possibleConditions={possibleConditions} setLead={setLead} lead={lead} setProfile={setProfile} profile={profile} />
                        </div>
                        <br/>What's your height and weight?<br/>
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'inline-block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('feet', e.target.value)} onChange={(e)=>handleScriptInput('feet', e.target.value)} value={lead[0].feet ? lead[0].feet : ''} placeholder='Lead Height - Feet'/>
                        </div>
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'inline-block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('inches', e.target.value)} onChange={(e)=>handleScriptInput('inches', e.target.value)} value={lead[0].inches ? lead[0].inches : ''} placeholder='Lead Height - Inches'/>
                        </div>
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('weight', e.target.value)} onChange={(e)=>handleScriptInput('weight', e.target.value)} value={lead[0].weight ? lead[0].weight : ''} placeholder='Lead weight'/>
                        </div>

                    </span>
                    <span className="numberList">
                        <span className="number">3.</span> 
                        What do you do for work, anything dangerous, like a stunt man/rodeo clown? <b>[Get a laugh]</b> What does your work schedule look like? (M-F 9-5?) What about your spouse?<br/><br/>
                    </span>
                    {lead[0].lead_type==='Mortgage Protection' && (
                        <span className="numberList">
                            <span className="number">4.</span> 
                            {lead[0].mortgage ? (
                                <>
                                    It says the amount of the mortgage to be covered is <b>$<input className="qualifierFieldInline" style={{width:'60px'}} type="text" onBlur={(e)=>handleUpdateLead('mortgage', e.target.value)} onChange={(e)=>handleScriptInput('mortgage', e.target.value)} value={lead[0].mortgage ? lead[0].mortgage : ''} placeholder='mortgage'/></b> is this correct? What is your monthly payment? Is that a 15-year or a 30-year?
                                    <div className="leadData" style={{ width: '50%', margin: '10px 0', display: 'block' }}>
                                        <select
                                            className="outlineButton filterButton"
                                            onChange={(e) => handleDropDown('term_length', e.target.value)}
                                            value={lead[0].term_length || ''}
                                        >
                                            <option value={false}>Mortgage Term Length</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                        </select>
                                    </div>
                                </>
                            ) : (
                                <>I want to double check, what is the current amount left on your mortgage? Is that a 15-year or a 30-year?
                                    <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'block'}}>
                                        <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('weight', e.target.value)} onChange={(e)=>handleScriptInput('mortgage', e.target.value)} value={lead[0].weight ? lead[0].weight : ''} placeholder='Lead weight'/>
                                    </div>
                                    <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'block'}}>
                                        <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('weight', e.target.value)} onChange={(e)=>handleScriptInput('weight', e.target.value)} value={lead[0].weight ? lead[0].weight : ''} placeholder='Lead weight'/>
                                    </div>
                                </>
                            )}
                            
                        </span>
                    )}

                    <span className="numberList">
                        <span className="number">{lead[0].lead_type==='Mortgage Protection' ? '5' : '4'}.</span> 
                        Most importantly, {lead[0].lead_type==='Mortgage Protection' ? 'when you sent in the form' : 'when you filled out the form'}, what was your main concern? Was it mostly for coverage on you<b>{lead[0].co_borrower_first ? ', '+lead[0].co_borrower_first+', or both?' : '?'}</b><br/><b>[Repeat back what you hear.]</b>
                    </span>
                </>
            )
        },
        {
            title:"Set Appointment",
            content: (
                <>
                    <p>Ok that makes a lot of sense. I'm going to do some homework for you and shop around with several of our top-rated carriers. Once I get the best options narrowed down, you{lead[0].co_borrower_first ? ', '+lead[0].co_borrower_first+',' : ''} and I will connect for a few minutes to review these options. Your job will be to pick out a plan that fits your needs and budget</p>
                </>
            )
        },
        {
            title:"Tie Down",
            content: (
                <>
                    <p>I have a crazy schedule this week, I'm working with a handful of families in your area on <b>[book within 48 hours]</b> and I have a <b>[Give Two Times]</b> appointment available. <b>[Give alternate choice-two options]</b></p>
                    <p>Which of those times work best for you <b>{lead[0].co_borrower_first ? ' and '+lead[0].co_borrower_first : '' }</b>?<br/><b>[Wait for response.]</b></p> 
                    <p>Okay so you're positive that time works for you<b>{lead[0].co_borrower_first ? ' and '+lead[0].co_borrower_first+' together' : '' }</b>?</p>
                    <p>Okay grab a pen and paper and let me know when you're ready. I am going to give you my information and a few things to have ready for our appointment.</p>
                    <p>My name is <b>{agentInfo.firstName}</b> and what time did we say again? <br/><br/>Okay perfect.</p>
                    <p>We will also need</p>
                    <p style={{marginLeft:'15px'}}>&bull; Identification</p>
                    <p style={{marginLeft:'15px'}}>&bull; Dr's info</p>
                    <p style={{marginLeft:'15px'}}>&bull; Medications</p>
                    <p>
                    Okay I have us for <input className="qualifierFieldInline" style={{width:'60px'}} type="text" onChange={(e)=>setDayTime1(e.target.value)} value={dayTime1} placeholder='Day'/> at <input className="qualifierFieldInline" style={{width:'60px'}} type="text" onChange={(e)=>setDayTime2(e.target.value)} value={dayTime2} placeholder='Time'/>? Sometimes the families that I am working with need a little extra time with me. Give me a 15-30 minute window and I will make sure I give you the time you need as well. If I am running more than 30 minutes behind, I will give you a call.<br/><br/>
                    Have a great rest of your day and I will look forward to seeing you on <b>{dayTime1}</b> at <b>{dayTime2}</b>.
                    </p>
                </>
            )
        }
    ],
    [
        {
            title:"Section 1: Rapport",
            content: (
                <>
                    <p>The agent should build rapport for a few minutes. Try to connect with the client and find commonality. Don't overdo this. Avoid being a “professional visitor.” Your tone should be friendly but in control.</p>
                    <p><b>Possible Topics</b></p>
                    <p style={{marginLeft:'15px'}}>&bull; Compliment them on connecting to Zoom if appropriate.</p>
                    <p style={{marginLeft:'15px'}}>&bull; New home or refinance? How long in the area? Moved from elsewhere?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Occupation — how did you get started in that?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Family — any family nearby?</p>
                </>
            )
        },
        {
            title:"Transition to Presentation",
            content: (
                <>
                    <p>So, let me start off by showing you a few things about me. <br/><b>[share screen if virtual]</b></p>
                    <p>Here's my insurance license... driver's license and business card... picture of my family... <br/><b>[this confirms your identity and purpose]</b></p>
                    <p>Let me show you, this is what you folks mailed in... <br/><b>[show the lead]</b></p>
                    <p>Which one of you responded? And I know we talked about this briefly on the phone with you {lead[0].borrower_first} — but now that we are all together — what was your main concern?<br/><b>[this is their “surface why”]</b>
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('motivation', e.target.value)} onChange={(e)=>handleScriptInput('motivation', e.target.value)} value={lead[0].motivation ? lead[0].motivation : ''} placeholder='Client "Why"'/>
                        </div>
                    </p>
        
                    <p><b>[Repeat back in your own words]</b><br/>So, what I am hearing is <b>{lead[0].motivation}</b>. Is that right? Well that makes sense for sure. And how long have you guys been thinking about this kind of protection?</p>
                </>
            )
        },
        {
            title:"What is Mortgage Protection?",
            content: (
                <>
                    <p>OK, {lead[0].borrower_first}, real quick let me explain what mortgage protection is:</p>
                    <p>You probably already know, but it is a life insurance policy for homeowners that will pay off the mortgage or make mortgage payments while the family is in transition after someone dies. Mortgage protection can also be used to replace income if the breadwinner has a critical illness or disability in their working years.</p>
                    <p>Now, this is not the old kind of mortgage protection. Meaning that your lender will not be your beneficiary. So, who would your beneficiary be {lead[0].borrower_first}?<br/> 
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('beneficiary', e.target.value)} onChange={(e)=>handleScriptInput('beneficiary', e.target.value)} value={lead[0].beneficiary ? lead[0].beneficiary : ''} placeholder='Beneficiary'/>
                        </div>
                    </p>
                    <p>So, {lead[0].beneficiary} will receive the benefit. One of the other major differences is that this is not a decreasing term. In other words, if something happens to you tomorrow or 10 years from now, your death benefit will remain the same. This policy is also portable, so if you refinance or move, you can take this mortgage protection with you.</p>
                    <p>Now, some people do a full mortgage payoff if that's in the cards. Some do half. A lot of people just do a little bit, just a little bit to help their family with the transition to the next step, okay? It really depends on two things. First, it depends on budget; everyone's got a budget. And second, it depends on what you qualify for in terms of age and health, make sense?
                    <br/><b>[Wait for response — they need to be engaged — keep them accountable to your questions early and that will carry throughout your presentation]</b>
                    </p>
                    <p>Now, it's important to know most people already have life insurance. It's really common. Some have it through work, some have their own policy, some have 401K money, maybe a rich uncle. I'm still waiting on mine <b>[laugh]</b>. Some have money in the bank. In other words, we all work to build up a nest egg, right?</p>
                    <p>That's why people get mortgage protection separately. They don't want their family to have to use the nest egg money to pay for the mortgage, they want that nest egg to go to the family, and they want the insurance company to pay off the mortgage debt. It's another way people use life insurance nowadays. Does that make sense?"</p>
                </>
            )
        },
        {
            title:"Explain Your Role and Purpose",
            content: (
                <>
                    <p>My role in all of this is what they call a 'field underwriter.' Each week I work with 12-15 families in your area who have all requested this type of insurance. I have access to all the top-rated companies that offer this coverage, so I am able to shop around for you. My job is to find you the best coverage you qualify for at the best price. <br/><b>[This eliminates the “I need to shop around” concern]</b></p>
                    <p>So, there are a few things we'll do today:</p>
                    <p>I'm going to verify your health information. Some carriers are super picky about prescription and medical concerns, others are not. So, I need to know the details so I can match you with the best company. I don't work for any one company - I work for you. So, please be honest with me so I can help get your family protected. The good news is that you don't have to see a nurse, give blood or urine, etc. with these programs. <br/><b>[This eases their mind about health issues and builds trust]</b></p>
                    <p>Then I have some detailed questions about your mortgage and your finances. Some of these might even be uncomfortable for me to ask, but I am not doing this to be nosey. I am doing this to make sure, in my professional opinion, that you have a need for this coverage. Not everyone has a need, and if that's the case with you, I will let you know honestly and be on my way. We find that most people need way less than they think they need, and if that's the case I can save you some money and be the hero. <br/><b>[laugh] <br/>[This helps to set up critical period concept or partial pay-offs and builds trust that you won't try to oversell them. It also sets up expectation for why we ask personal financial questions]</b></p>
                    <p>Once we narrow this down to a few programs that I think you will qualify for, your job is to pick out the program that best suits your needs and your budget. <br/><b>[Lets them know they have an active role or “job” in this process and are in control]</b></p>
                    <p>As we're talking, I'll be filling out some “paperwork” on my screen, [if virtual] and this is what we'll be submitting to the insurance company tonight to get their final approval: which could take two to seven days. <br/><b>[Lets them know business is taking place today]</b></p>
                    <p>Once they approve the application your family will be protected. But it usually takes about 10-14 days to get the policy mailed to you. At which time we'll get back on the phone or computer, we'll go over everything together in detail and make any changes if needed. Sound good? <br/><b>[This gets your first “yes” from your client]</b></p>
                </>
            )
        },
        {
            title:"Section 2: Emotion: Explore their situation",
            content: (
                <>
                    <p><i style={{fontSize:'14px'}}>Health is the easiest place to start because we already began to review that on the phone and it's less emotional of a topic. Next we dig into finances, which will do the heavy lifting for creating the emotion of why they need this. Numbers don't lie.</i></p>
                </>
            )
        },
        {
            title:"Verify Health",
            content: (
                <>
                    <p>First, we need to verify the medical side to confirm which carriers you would qualify with. <br/><b>[Review the CQF form from your phone call]</b></p>
                    <p>I am going to share my screen [if virtual] and run through a list of about 15 health questions. Some of these are going to sound crazy but I have to ask them anyway. [laugh] And if any are “yes” don't worry - I just need your full honesty like I mentioned earlier. So just really quickly, what medications are you prescribed....<br/><b>[Review full medical information]</b></p>
                    <div className="scriptLineBreak">
                        <FdaBlock setPossibleConditions={setPossibleConditions} possibleConditions={possibleConditions} setLead={setLead} lead={lead} setProfile={setProfile} profile={profile} />
                    </div>
                </>
            )
        },
        {
            title:"Financial Information Form (FIF) and Cashflow Analysis",
            content: (
                <>
                    <p><span style={{fontWeight:'700'}}>This very important part of the process is used to set up future Preferred Client Services (PCS) needs. The FIF is required for every client.</span></p>
                    <p>Okay so now I want you guys to grab a pen and paper and let me know when you are ready. <br/><b>[If virtual — at this point you can share a whiteboard screen on Zoom or simply grab your FIF form and write with the client. If in-person you will fill out the FIF with the clients observing you. We want client engagement in this process, so have them actively participate]</b></p>
                    <p>So that we can get a better picture of what cash flow would look like, we are going to do a quick financial review.</p>
                    <div className="scriptSubTitle">Get The Equity Number</div>
                    <p>
                        <b>[Start your fact finding by discovering the amount of equity they have in the home. This is a critical step for any Equity Protection plan during the critical period.]</b><br/>
                        So, your mortgage is about <input className="qualifierFieldInline" style={{width:'60px'}} type="text" onBlur={(e)=>handleUpdateLead('mortgage', e.target.value)} onChange={(e)=>handleScriptInput('mortgage', e.target.value)} value={lead[0].mortgage ? lead[0].mortgage : ''} placeholder='Mortgage'/>, right? And what are your payments on that? <input className="qualifierFieldInline" style={{width:'100px'}} type="text" onBlur={(e)=>handleUpdateLead('payment', e.target.value)} onChange={(e)=>handleScriptInput('payment', e.target.value)} value={lead[0].payment ? lead[0].payment : ''} placeholder='Mothly Payment'/>Let's write that at the top of the page. And let me ask you, if you had the time to prep the home and put it on the market tomorrow and didn't have to take a low offer, you were patient and got the full value, what would it sell for? <input className="qualifierFieldInline" style={{width:'80px'}} type="text" onBlur={(e)=>handleUpdateLead('home_value', e.target.value)} onChange={(e)=>handleScriptInput('home_value', e.target.value)} value={lead[0].home_value ? lead[0].home_value : ''} placeholder='Home Value'/> Okay write that number next to what you owe. Okay, if we subtract what is owed from what it would sell for, we get <b>$ {(lead[0].home_value - lead[0].mortgage)}</b> in equity. Is that what you see as well?
                    </p>
                    <p><b>[People should be proud of their equity - congratulate them]</b><br/> And that's today. 10 years from now, it's thousands more, 15 or 20 years from now it can be hundreds of thousands more. As time goes by, what you owe goes down and what it's worth goes up. This equity often is the biggest part of the legacy left for a family. Does that make sense? Let's write the equity just below those numbers.</p>
                    <p>For most families, the number one job for mortgage protection is to make sure that equity stays with the family and never walks out your front door, right? That's your family's money, part of your nest egg, it should never go to the bank or to a low-ball investor buying your home as a distressed sale, right? So, at a minimum we need to protect that equity. Do you see that?</p>
                    <p>Now let's do a quick review of major household expenses.</p>
                    <p>
                        <b>[List out mortgage payment, all major debts, and all major monthly expenses to achieve a “quick budget.” This helps to avoid the “need to check our budget” and also shows where cash flow will be a problem. Don't get mired down in a lot of detail here. We just need approximate numbers. We don't want to frustrate the client with too many details.]</b>
                    </p>
                    <p>When we add this up it looks like about $<input className="qualifierFieldInline" style={{width:'60px'}} type="text" onBlur={(e)=>handleUpdateLead('expenses', e.target.value)} onChange={(e)=>handleScriptInput('expenses', e.target.value)} value={lead[0].expenses ? lead[0].expenses : ''} placeholder='Expenses'/> comes out for the bills every month. Does that seem about right?</p>
                </>
            )
        },
        {
            title:"Uncover the Cash Flow Problem",
            content: (
                <>
                    <p><span style={{fontWeight:'500'}}>Cash flow is the problem we are trying to solve. Helping the client understand the cash flow problem when someone passes will create the emotion.</span></p>
                    <p>Now, let's make two columns on the page. One for you, <b>{lead[0].borrower_first}</b> and one for <b>{lead[0].co_borrower_first}</b>.</p>
                    <p>
                        <b>{lead[0].borrower_first}</b>, if you didn't come home last night, what is already in place that would pass on to <b>{lead[0].co_borrower_first ? lead[0].co_borrower_first : lead[0].beneficiary}</b> to help them keep this house and this lifestyle, such as other life insurance, savings, retirement accounts?,<br/><b>[Go through these one by one and ask questions for each based on the FIF form]</b>
                    </p>
                    {lead[0].co_borrower_first && (
                        <p>And <b>{lead[0].co_borrower_first}</b> same questions for you: what is already in place today that would pass on to <b>{lead[0].borrower_first}</b> to help them keep the house and the lifestyle?</p>
                    )}
                    <p>
                        Okay, let's talk about income. <b>{lead[0].borrower_first}</b> what do you bring in on a monthly basis? And <b>{lead[0].co_borrower_first}</b>?
                        <br/><div className="leadData" style={{width: '48%',margin: '10px 0px', display:'inline-block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_income', e.target.value)} onChange={(e)=>handleScriptInput('borrower_income', e.target.value)} value={lead[0].borrower_income ? lead[0].borrower_income : ''} placeholder='Income'/>
                        </div>
                        <div className="leadData" style={{width: '48%',margin: '10px 0px', display:'inline-block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_income', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_income', e.target.value)} value={lead[0].co_borrower_income ? lead[0].co_borrower_income : ''} placeholder='Co Income'/>
                        </div>
                    </p>
                    <p>Any pensions for either of you? Military benefits for either of you? Any other income? So, it looks like aprox <b>${((lead[0].borrower_income ? parseInt(lead[0].borrower_income) : 0) + (lead[0].co_borrower_income ? parseInt(lead[0].co_borrower_income) : 0))}</b> is coming into the house every month, correct? <br/><b>[Write down this total]</b></p>
                    <p>Now, if something happened to you first <b>{lead[0].borrower_first}</b>, how much money would stop coming into the house from your side every month? Would any income transfer over? Okay. 
                    {lead[0].borrower_first && (
                        <>And what about you <b>{lead[0].borrower_first}</b> how much money would stop coming in if something happened to you?</>
                    )}
                    </p>
                    <p><b>[You may need to help clients understand what happens to Social Security benefits when a spouse passes. Ask if they understand the SS process? If married, the surviving spouse is able to keep the higher of the two SS incomes but the other SS income goes away]</b></p>
                </>
            )
        },
        {
            title:"Cash Flow Analysis",
            content: (
                <>
                    <p><span style={{fontWeight:'500'}}>We now take time to do the math with the client. <b>$ {((lead[0].borrower_income ? parseInt(lead[0].borrower_income) : 0) + (lead[0].co_borrower_income ? parseInt(lead[0].co_borrower_income) : 0))}</b> is the combined income coming into the house every month and <b>$ {((lead[0].expenses ? parseInt(lead[0].expenses) : 0) + (lead[0].payment ? parseInt(lead[0].payment) : 0))}</b> is the total approximate amount of household expenses.</span></p>
                    <p>Without <b>{lead[0].borrower_income}</b>, it looks like you would be left, <b>{lead[0].co_borrower_first}</b>, with <b>{((lead[0].co_borrower_income ? parseInt(lead[0].co_borrower_income) : 0) - ((lead[0].payment ? parseInt(lead[0].payment) : 0) + (lead[0].expenses ? parseInt(lead[0].expenses) : 0)))}</b>. And without <b>${lead[0].co_borrower_income ? lead[0].co_borrower_income : 0}</b>, it looks like you would be left, <b>{lead[0].borrower_first}</b>, with <b>{((lead[0].borrower_income ? parseInt(lead[0].borrower_income) : 0) - ((lead[0].payment ? parseInt(lead[0].payment) : 0) + (lead[0].expenses ? parseInt(lead[0].expenses) : 0)))}</b>. Is that what you guys see? <br/><b>[At this point the cash flow problem and need should be obvious. Take your time here and let this sink in.]</b></p>
                    <p>
                        <b>{lead[0].borrower_first}</b>, if you didn't come home last night, what is already in place that would pass on to <b>{lead[0].co_borrower_first ? lead[0].co_borrower_first : lead[0].beneficiary}</b> to help them keep this house and this lifestyle, such as other life insurance, savings, retirement accounts?,<br/><b>[Go through these one by one and ask questions for each based on the FIF form]</b>
                    </p>
                    <p>Okay, I can better understand now why you responded to the letter.</p>
                </>
            )
        },
        {
            title:"Quality Questions",
            content: (
                <>
                    <p><b>[Select a few of these questions to ask based on the situation, the program and your personal preference]</b></p>
                    <p style={{marginLeft:'15px'}}>&bull; How long would you want to stay in this house by yourself?</p>
                    <p style={{marginLeft:'15px'}}>&bull; How long could you stay in this house?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Where would you want to go?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Who would have to move in with you?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Knowing this cash flow analysis, what specific challenges would you have?</p>
                    <p style={{marginLeft:'15px'}}>&bull; What would you have to do to maintain the mortgage and your lifestyle?</p>
                    <p style={{marginLeft:'15px'}}>&bull; What does that look like?</p>
                    <p style={{marginLeft:'15px'}}>&bull; How do you feel about that <b>{lead[0].co_borrower_first}</b>?</p>
                    <p style={{marginLeft:'15px'}}>&bull; What are your thoughts <b>{lead[0].co_borrower_first}</b>?</p>
                    <p style={{marginLeft:'15px'}}>&bull; How long do houses take to sell around here?</p>
                    <p><b>If Single</b></p>
                    <p style={{marginLeft:'15px'}}>&bull; What would the kids do with this house?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Which of your kids would be making this mortgage payment?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Do they have a mortgage as well that they would need to also pay?</p>
                    <p style={{marginLeft:'15px'}}>&bull; How hard would it be for your kids to make their own mortgage/rent and this one?</p>
                    <p style={{marginLeft:'15px'}}>&bull; How long do houses take to sell around here?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Would all of the kids move in here together or would one child most likely need to?</p>
                    <p style={{marginLeft:'15px'}}>&bull; Would one child buy-out the others?</p>
                </>
            )
        },
        {
            title:"Section 3: Assumption",
            content: (
                <>
                    <p><span style={{fontWeight:'500'}}>We can assume that our clients want the coverage. Remember that price is only important in the absence of value. We don't sell on “features and benefits,” we use those to lock down the sale after it is made. We sell based on needs.</span> <br/><b>[At this point in the presentation you have a good idea which road you are taking them down: Full/ Partial or Critical Period]</b></p>
                </>
            )
        },
        {
            title:"The “Think About It”",
            content: (
                <>
                    <p>Based on what you just shared with me, let's look at some programs. I am going to include at least one that is our most comprehensive, but I am going to count on you to be honest about your budget. Sometimes homeowners will beat around the bush and say things like 'I need to think about it, sleep on it, etc.' I've been doing this long enough to know what that means. It means the price isn't right. So, if what I'm showing you doesn't fit the budget, can I count on you to be honest with me? These are just some of the programs and we will definitely find you something tonight that fits comfortably inside of your budget as a good starting place, OK?</p>
                </>
            )
        },
        {
            title:"Explain Programs",
            content: (
                <>
                    <span className="numberList">
                        <span className="number">1.</span> <b>Full Payoff</b><br/><b>[Explain full payoff, Living Benefits and ROP to those clients who are age 65 or less. Note that some benefits such as ROP and disability riders have age restrictions]</b>
                        <p><b>**[If you select Critical Period, no need to go into a lot of detail here. We don't want the client getting excited about something they most likely cannot get or afford]</b></p>
                        <p>Full coverage is generally the most expensive and hardest to qualify for. And 95% of the time I can find something more affordable for my clients over 65 years old.</p>
                    </span>
                    <span className="numberList">
                        <span className="number">2.</span> <b>Partial Payoff</b><br/>
                        <p>As we discussed earlier, this is great for families that have two people bringing in the income. Especially during their working years. Let's say one person cannot work or dies, it is always helpful to have half the mortgage paid down or a big chunk paid down so it is affordable for the remaining person. For example, <b>{lead[0].co_borrower_first ? lead[0].co_borrower_first : lead[0].beneficiary}</b> if <b>{lead[0].borrower_first}</b> dies and I brought you a check for $50,000 even though your mortgage is $100,000 would you be mad at me? What could you do with that $50,000? <br/> <b>[Stop here and get clients to engage with you. Have them tell you what they could do with a lump sum of money. Ex. pay down the loan, refinance for lower payment, down payment on a smaller place, etc]</b></p>
                        <p><b>[If going critical period]</b><br/>With your age and health, honestly <b>{lead[0].borrower_first}</b> this one can also be hard to qualify for. For really anyone over 65, but is a little cheaper than full pay-off.</p>
                    </span>
                    <span className="numberList">
                        <span className="number">3.</span> <b>Critical Period/Equity Protection<br/>[If under age 65 and healthy, don't present this option]</b>
                        <p>Most of my clients that are around your age with similar health go for option three. It is the most affordable and the easiest to qualify for.</p>
                        <p>It's called Critical Period Coverage or Mortgage Payment Protection because it covers the most critical period of time after the loss of a spouse or loved one when the family is at most risk of losing the home. Let me explain.</p>
                        <p><b>[Start with talking to the person who would be in the most trouble if something happened to the breadwinner]</b><br/>If something were to happen to <b>{lead[0].borrower_first}</b> tomorrow, does the bank come to <b>{lead[0].beneficiary}</b> and ask for the full <b>$ {lead[0].mortgage}</b> you owe?</p>
                        <p>No, they just want what?</p>
                        <p><b>[Stop, engage client and get client to say the bank just wants the payment]</b><br/>People go into foreclosure not because the bank wants the payoff but because the bank wants the payment. It would be nice if the bank would call you up and say “We're so sorry for your loss and were going to waive your payments for the next six months to a year” but they don't do that, do they? No, they probably won't even know if someone has died. They just want the payment.</p>
                        <p>What this program option is going to do for you is buy you some time. Time to grieve properly, time to make a plan and time to put the house on the market long enough to get the top dollar for it without your family having to come out of pocket to make that mortgage payment each month. In my opinion, I would not spend a bunch of money trying to pay off a house that you don't plan on staying in anyway. Instead, the policy would cover mortgage payments long enough to sell it and get out the equity. That equity can become the benefit for {lead[0].beneficiary} or the legacy for your family. Many times, the equity in the home can be the biggest asset a family has! Does that make sense?</p>
                        <p>Of these three ways I just described, which one should we begin pricing?</p>
                    </span>
                </>
            )
        },
        {
            title:"Show Options",
            content: (
                <>
                    <p><b>[If critical period option - start with the number of months they give you in this question]</b><br/>Knowing that most people will need at least a few months to grieve - How long do you think it would take you or your kids after that to make a plan, pack up, get the house ready to sell and allow time on the market for it to receive the highest offer? <br/><b>[Ex. they say it would take at least a year, show 12 months of mortgage payments, nine months and 15 months]</b></p>
                    <p><b>[Just before you show the numbers, go ahead and start answering the most common questions we know will be asked about the coverage before they arise. “Just so that you know some more details of this program... this is a ______ [term/whole] life program... Premiums won't increase... Death benefit won't decrease... the money will be delivered in a lump sum to your beneficiary, etc. This is the time where you can showcase free riders, level premiums, cash value accumulation or any features and benefits that will enhance the program.]</b></p>
                </>
            )
        },
        {
            title:"The Close",
            content: (
                <>
                    <p>Okay, now which one of these fits most comfortably inside your budget? <br/><b>[Be quiet here and give them time to answer]</b></p>
                    <p>Okay, from here I am going to share my screen <b>[begin sharing your screen if virtual]</b>and finish filling out your paperwork. Go ahead and grab your driver's license for me. While you are doing that, what is the full name of your beneficiary?</p>
                </>
            )
        },
        {
            title:"Section 4: Lock It Down",
            content: (
                <>
                    <p><span style={{fontWeight:'500'}}>If you do not make the sale, skip to the “Client Survey” section of this script.</span></p>
                </>
            )
        },
        {
            title:"Secure the Sale",
            content: (
                <>
                    <p>One thing I take pride in is making the right recommendations to my clients and making sure that you're satisfied with your program. I'd like you to tell me in your own words - What did we just do here today?</p>
                    <p><b>[This will ensure policy persistence. Have the clients repeat back to you and use prompts if needed. How much coverage did we put in place (or how many months of mortgage payments)? What is that coverage for? How much will it cost monthly? Approximately how soon do we expect the first payment to draft? Why did we choose this program?]</b></p>
                    <p>What questions do you have for me before I leave?</p>
                    <p>The program we chose today — do you have peace of mind that this will help your family?</p>
                    <p>Over the next several days I'm going to work with the insurance company to get your policy approved. I will be checking in with you every few days to let you know how things are going. When the policy is approved, I will come back out and we will review it together, or we can arrange a phone call to review. We will also be doing an annual policy review with someone from our Member Services department.</p>
                    <p>Also let's take a minute to save my number in your phone.<br/><b>[Having clients save the number ensures that they recognize the number when you call]</b></p>
                    <p>Contact me anytime for anything. I'm your agent now. If anyone else calls you about life insurance feel free to give them my number and have them call me. Our company may send you a few more letters in the mail — you can ignore those now that we have a program in place for you.</p>
                </>
            )
        },
        {
            title:"Client Survey/The 5 R's",
            content: (
                <>
                    <p><b>[If the client has assets and/or if they are still working - Complete this step regardless of whether or not you make the sale.]</b><br/>Lastly before I leave, given some of the information you gave me earlier - I just have a brief client survey to complete with you. Our Client Services department can help clients with several aspects of their overall financial plan and I want to make sure we aren't missing anything before I leave.</p>
                    <p><b>[Refer to the Client Survey sheet and begin completing. Book a followup appointment based on answers from the survey.]</b></p>
                </>
            )
        }
    ]
];
    const dispatch=useDispatch();
    const [selectedTab, setSelectedTab] = useState('B.E.S.T. Script');
    const containerRef = useRef(null);
    const cqfRef = useRef(null);
    const suitabilityRef = useRef(null);
    const cqfFindingRef = useRef(null);

    const scrollToDiv = (ref) => {
        setSelectedTab(ref.current.textContent)
        if (containerRef.current && ref.current) {
            containerRef.current.scrollTo({
                top: ref.current.offsetTop - 100, // Scroll to the target div's offset, minus 30px
                behavior: 'smooth'
            });
        }
    };
    return (
        <>
            <div className="sideTabs">
                {/*<div onClick={()=>handleSetAppt(true)} className="sideTab outlineQuilityActiveButton">Set Appointment</div>*/}

                
                <div onClick={()=>[setSelectedScript(0),setSelectedTab('B.E.S.T. Script')]} className={`sideTab  ${selectedTab==='B.E.S.T. Script' && 'selectedSideTab'}`}>B.E.S.T. Script</div>
                <div onClick={()=>[setSelectedScript(1),setSelectedTab('R.E.A.L. Script')]} className={`sideTab  ${selectedTab==='R.E.A.L. Script' && 'selectedSideTab'}`}>R.E.A.L. Script</div>
                {/*<div onClick={()=>scrollToDiv(cqfRef)} className={`sideTab  ${selectedTab==='CQF Verification' && 'selectedSideTab'}`}>Establish Reason</div>
                <div onClick={()=>scrollToDiv(suitabilityRef)} className={`sideTab  ${selectedTab==='Financial Suitability' && 'selectedSideTab'}`}>Set Appointment</div>
                <div onClick={()=>scrollToDiv(cqfFindingRef)} className={`sideTab  ${selectedTab==='Why-CQF Findings' && 'selectedSideTab'}`}>Tie Down</div>*/}
            </div> 
            <div className="scriptingWrapper scriptAdjustment" ref={containerRef}>
                <div className="scriptTitle">{selectedTab}</div>
                {elements[selectedScript].map((element, index) => (
                    <ScriptBlock title={element.title} content={element.content} />
                ))}
            </div>
            {addedSpouse && (
                <div className="signInWrapper">
                    <div className="signInContent">
                        <div className="loginWrapper">
                            <div className="title" style={{marginTop:'0px', marginBottom:'15px'}}>Add Spouse</div>
                            <div className="spouseInputHolder">
                                {coLeadInput.map((field, index) => (
                                    <div className="leadData" style={{margin: '10px 0px', display:'block'}} key={`spouse${index}`}>
                                        <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead(field, e.target.value)} onChange={(e)=>handleScriptInput(field, e.target.value)} value={lead[0][field] ? lead[0][field] : ''} placeholder={field.replace(/_/g, " ").replace("borrower","lead")}/>
                                    </div>
                                ))}
                            </div>
                            <div className="buttonHolder">
                                <div className="standardButton solidQuilityDestructiveButton" style={{display:'inline-block', width:'30%',border: 'none', margin:'5px'}} onClick={()=>{setAddedSpouse(false)}}>Cancel</div>
                                <div className="standardButton solidQuilityActiveButton" style={{display:'inline-block', width:'30%',border: 'none', margin:'5px'}} onClick={()=>{setAddedSpouse(false)}}>Save</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}





export default Script;

