import React, {useEffect,useState} from 'react';
import '../css/login.css';
import UpgradePassword from '../upgradePassword';
import ResetPassword from './resetPassword';
import image from '../assets/icons/QuilitySwitchboardFunnel.svg';
import { useDispatch } from 'react-redux';
import { setUserData } from '../features/agentSlice';
import { fetchWithDefaults } from '../config/fetchDefault';
import {gatherTheme,applyTheme} from '../utilities/appSetup';
import { setBackup, setQueue, setQueueSort, setWholeQueue } from '../features/queueSlice';
import { setConversations } from '../features/conversationSlice';

function LoginPage({agentPw, setUpdatePassword, saveData, loginError, setLoginError, setSignedIn, setAgentIdNumber, agentUsername, updatePassword, setAgentUsername, setAgentPw, loginUser}) {
    const [newPassword, setNewPassword] = useState(false);
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const passwordReset = params.get('resetCode');
    if(passwordReset){
        const validateReset= async (code)=>{
            try {
                const response = await fetchWithDefaults(`/loginReset/validateReset?code=${code}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData.status){
                    setUpdatePassword(true);
                    setAgentUsername(jsonData.agent);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        validateReset(passwordReset);
    }
    const decrypt = async (string) => {
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    cipher: string,
                    source:'web'
                }) 
            };

            const response = await fetchWithDefaults('/login/decrypt', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const jsonData = await response.json();
            let admin=false;
            if(jsonData[0].admin===1){
                admin=true;
            } 
            const root = document.documentElement;
            const theme=await gatherTheme(jsonData[0].organization);
            await applyTheme(theme,root,dispatch);
            dispatch(setUserData({
                agentId: jsonData[0].id,
                organization: jsonData[0].organization,
                admin:admin,
                organizationName:jsonData[0].orgName,
                permissions:jsonData[0].permissions,
                agentCode:jsonData[0].agentCode,
                beta:jsonData[0].beta===0 ? false : true,
                a2pOnly:jsonData[0].beta===2 ? true : false,
                status:jsonData[0].agentStatus==='inactive' ? false : true,
            }))

            setAgentIdNumber(jsonData[0].id);
            setSignedIn(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const loadData = async () => {
        const storedData = localStorage.getItem('keepMeAlive');
        if(storedData){
            decrypt(storedData)
        }
    };
    const handleResetPassword=()=>{
        setNewPassword(!newPassword);
    }
    useEffect(() => {
        dispatch(setConversations([]));
        dispatch(setBackup([]))
        dispatch(setQueue([]))
        dispatch(setWholeQueue([]))
        dispatch(setQueueSort([]))
        loadData();
        return () => {
            setAgentUsername('')
            setAgentPw('')
        };
    },[])
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginUser();
        }
    };
    const sso=()=>{
        window.location.href=`https://quility.auth0.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://${window.location.hostname}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=init`
    }
    return (
        <div className="mainBackground">
        {newPassword && (
            <ResetPassword handleResetPassword={handleResetPassword}/>
        )}
            <>
                {updatePassword && (
                    <UpgradePassword saveData={saveData} setSignedIn={setSignedIn} setAgentIdNumber={setAgentIdNumber} agentUsername={agentUsername} />
                )}
                
    
                <div className="loginWrapper">
                    <div className="logo"><img src={image} className="imageStyle" alt="Logo" /></div>
                   
                    <div className="error" style={{ color: '#ff4949', fontWeight: '400', fontSize: '18px' }}>{loginError}</div>

                    <div className="errorHolder"></div>
                    <div className="usernameWrapper">
                        <div className="loginInputTitle">Username</div>
                        <input
                            type="text"
                            className="inputBox"
                            placeholder="User Name"
                            value={agentUsername}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setAgentUsername(e.target.value)}
                        />
                    </div>
                    <div className="passwordWrapper">
                        <div className="loginInputTitle">Password</div>
                        <input
                            type="password"
                            className="inputBox"
                            value={agentPw}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setAgentPw(e.target.value)}
                            placeholder="Password"
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className="forgotPasswordWrapper"><span className="forgotPassword" onClick={()=> handleResetPassword()}>Forgot your password?</span></div>
                    {/*<div className="forgotPasswordWrapper"><a className="forgotPassword" href="forgotUsername">Forgot your Username?</a></div>*/}
                    <div className="buttonWrapper" style={{marginTop: '20px'}}>
                        <button type="submit" className="loginButton" name="submitSignin" onClick={() => loginUser()}>Login</button>
                    </div>
                </div>
            </>

        </div>
    );
}
//<span className="symbol"><img src={symbol} className="" alt="Logo" /></span> 
export default LoginPage;